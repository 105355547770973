import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import BlocksContent from "../components/blocksContent"
import LayOut from "../components/layout"
import MyLink from "../components/myLink"
import "./addons-integration-template.sass"

const AddOnsTemplate = ({ pageContext: data }) => {
  const {
    sanityAddonsPage: {
      heroHeader: {
        mainContent: { _rawColumnContent: headerContentBlocks },
      },
    },
  } = useStaticQuery(graphql`
    query AddonsHeaderForTemplateQuery {
      sanityAddonsPage {
        heroHeader {
          mainContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `)

  return (
    <LayOut>
      <div className="addons-integration-template">
        <div className="header">
          <div className="container">
            <div className="left-column">
              <div className="blocks-content">
                <BlocksContent blocks={headerContentBlocks} />
              </div>
              <button
                onClick={() => {
                  window.history.back()
                }}
              >
                &lt; Back
              </button>
            </div>

            <div className="right-column">
              {data.image && (
                <GatsbyImage
                  image={data.image.asset.gatsbyImageData}
                  alt={data.image.asset.originalFilename}
                />
              )}
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            {/* content - left part */}
            <div className="left-column">
              <div className="title">
                <h3>{data.title}</h3>
                <p>{data.subTitle}</p>
              </div>
              <div className="blocks-content">
                {data.content && (
                  <BlocksContent blocks={data.content._rawColumnContent} />
                )}
              </div>
            </div>

            {/* content - right part */}
            <div className="right-column">
              {data.price && (
                <span>
                  <b>Price:</b> {data.price}
                </span>
              )}

              {data.author && (
                <span>
                  <b>Author:</b> {data.author}
                </span>
              )}

              <Link to="/contact/" className="contact-btn">
                Contact Us
              </Link>
              <MyLink {...data.installLink} className="install-link" />
            </div>
          </div>
        </div>
      </div>
    </LayOut>
  )
}

export default AddOnsTemplate
